import React from "react"
// import { graphql } from "gatsby"
// import { Link } from "gatsby"
import "../gatsby-theme-brainless/components/all-pages.css"
import { Helmet } from "react-helmet";
import Layout from "../components/layout"

const AllTagsTemplate = ({data, pageContext}) => {
  //console.log(data, pageContext)
  const { tags } = pageContext

  let tagList = [];

  // console.log(brainNotes);
  tagList = tags.map((tag, index) => (
    <>
        <li key={index} className="index-item">
          <span className="link-container">
            <a className="link" href={`tags/${tag}`}>{tag.charAt(0).toUpperCase() + tag.slice(1)}</a>
          </span>
          <hr className="index-rule"/>
        </li>
    </>
  ));

  return (
    <>
    <Helmet>
      <title>
        All Tags
      </title>
    </Helmet>
    <Layout>
      <h1 id="all-pages-title"> All Tags </h1>
      <div id="notes-container"> {tagList} </div>
    </Layout>
    </>
  )
}

export default AllTagsTemplate
